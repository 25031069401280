export default defineStore('SelectFieldPopupStore', {
    state: () => ({
        isOpenValue: false,
    }),

    actions: {
        toggleState(forceClose = false) {
            this.isOpenValue = forceClose ? false : !this.isOpenValue;
        },
    },

    getters: {
        isOpen(state) {
            return state.isOpenValue;
        },
    },
});
