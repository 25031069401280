<template>
  <div
    ref="wrapperRef"
    class="form-input-select form-field"
    :class="{
      'has-content': hasContent,
      'is-empty': computedValues?.options?.length === 0,
      'has-error': errors?.length > 0,
      'is-open': isOpen,
      'is-multi-select': data.is_multi_select,
      'is-disabled': isHidden,
    }"
  >
    <button
      type="button"
      class="form-select__trigger"
      :class="{
        'is-hidden': selectFieldOpen,
        'is-full-screen': clientStore.isMobileValue,
      }"
      @click="toggleOpen()"
    >
      <span class="form-select__label">
        {{
          data.label
            + (data.is_required ? ' *' : '')
        }}
      </span>

      <transition name="fade-in">
        <span
          v-if="data.is_multi_select === false && hasContent"
          class="form-select__value"
        >
          {{ selectedValues[0] }}
        </span>
      </transition>

      <AppIcon icon="arrow-down" stroke="#003E30" max-width="15px" />

      <div
        v-if="selectedValues.length > 0 && data.is_multi_select"
        class="form-select__amount"
      >
        {{ selectedValues.length }}
      </div>
    </button>

    <Teleport
      v-if="isFullScreen && clientStore.isMobile.value"
      :key="data._uid"
      to="body"
    >
      <AppFilterPopup
        :wrapper-key="data._uid"
        :label="data.label"
        :is-open="isFullScreenOpen"
        :options="computedValues?.options"
        :selected-values="selectedValues"
        @emit-values="handleSelection($event)"
        @emit-open="toggleOpen($event)"
        @emit-reset="resetSelection()"
      />
    </Teleport>

    <ul v-else ref="optionsRef" class="form-select__options">
      <li
        v-for="option of computedValues?.options"
        :key="option._uid"
        :class="{ checked: selectedValues.includes(option.value) }"
        class="form-select__option"
        @click="handleSelection(option.value)"
        @keyup="handleSelection(option.value)"
      >
        <span class="form-select__option-background" />

        {{ option.value }}
        <span v-if="option?.count" class="form-select__option-count">
          {{ option?.count }}
        </span>

        <span class="form-select__option-checked ">
          <svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.0899 7.2279L0 4.138L0.59315 3.54485L3.0899 6.0416L8.38687 0.744629L8.98002 1.33778L3.0899 7.2279Z" fill="white" />
          </svg>
        </span>
      </li>
    </ul>

    <FormFieldErrors :errors="errors" />
  </div>
</template>

<script setup>
import { onClickOutside } from '@vueuse/core';

const emit = defineEmits(['emitValue', 'hasErrors']);

const props = defineProps({
    data: {
        type: Object,
        default: () => {},
    },
    prefillValue: {
        type: [String, Array],
        default: '',
    },
    options: {
        type: Array,
        default: () => [],
    },
    isFullScreen: {
        type: Boolean,
        default: false,
    },
    isHidden: {
        type: Boolean,
        default: false,
    },
});

const { buildValidationObject } = useFormValidation();
const {
    hasContent,
    errors,
    handleEmit,
    setValue,
    setFocus,
} = useFormField(emit, buildValidationObject(props.data));
/*
    injected data from strapi and prepare for form
*/
const injectedOptions = inject('options', { value: false });

const computedValues = computed(() => ({
    options: injectedOptions?.value?.date
        || injectedOptions?.value
        || props.data?.options
        || props.options,
}));

/*
  Custom select functionality
*/
const selectedValues = reactive([]);
const isOpen = ref(false);
const wrapperRef = ref(null);
const optionsRef = ref(null);
const optionsHeight = ref('6px');

const clientStore = storeToRefs(useClientDeviceStore());

/* Toggle state of select field */
const selectFieldPopupStore = useSelectFieldPopupStore();
const selectFieldOpen = computed(() => selectFieldPopupStore.isOpen);

const setOptionsHeight = () => {
    const newHeight = isOpen.value ? optionsRef.value.scrollHeight : 6;
    const maxHeight = window.innerHeight * 0.4;

    optionsHeight.value = `${newHeight > maxHeight ? maxHeight : newHeight}px`;
};

const isFullScreenOpen = ref(false);
const toggleOpen = (forceClose = false) => {
    if (props.isFullScreen && clientStore.isMobile.value) {
        isFullScreenOpen.value = forceClose ? false : !isFullScreenOpen.value;
    }

    if (!props.isFullScreen || !clientStore.isMobile.value) {
        isOpen.value = !isOpen.value;
        setFocus(!!isOpen.value);

        setOptionsHeight();
    }
};

/*  Watch window width to set height of box accordingly on resize */
watch(() => clientStore.windowWidth, () => {
    if (!props.isFullScreen && !clientStore.isMobile.value) {
        setOptionsHeight();
    }
});

/* Selection handling */
const handleSelection = (value, toggle = true) => {
    if (props.data.is_multi_select) {
        if (!selectedValues.includes(value)) {
            if (typeof value === 'string') {
                selectedValues.push(value);
            } else {
                selectedValues.push(...value);
            }
        } else {
            selectedValues.splice(selectedValues.indexOf(value), 1);
        }
    } else {
        selectedValues[0] = value;

        if (toggle) {
            window.setTimeout(() => {
                toggleOpen();
            }, 400);
        }
    }

    /* Emit data */
    const dataToEmit = props.data.is_multi_select ? [...selectedValues] : selectedValues[0];
    setValue(dataToEmit);
    handleEmit(dataToEmit, props.data.field_key);
};

const resetSelection = () => {
    selectedValues.length = 0;
    setValue([]);
    handleEmit([], props.data.field_key);
};

/* Close dropdown on click outside */
onClickOutside(wrapperRef, () => {
    if (isOpen.value) {
        toggleOpen();
    }
});

onMounted(() => {
    if (props.prefillValue) {
        const value = props.prefillValue;
        handleSelection(value, false);
    }
});
</script>

<style lang="scss" scoped>
.form-input__element {
    @include form-input-element;
    padding-top: unset;
}

.form-input-select {
    @include form-input-wrapper;

    /* Margin needed for Safari */
    margin-top: 1px;
    background: $C_WHITE;

    @for $i from 1 through 100 {
        &.is-open:nth-child(#{$i}) {
            z-index: #{100 - $i};
        }
    }

    .filter-advanced & {
        margin-bottom: 10px;
    }

    &.is-disabled {
        opacity: 0.5;
        pointer-events: none;
    }
}

.form-select__label {
    @include typo-font('bold');
    @include fluid('top', 14px, 19px);
    position: absolute;
    z-index: 1;
    left: 25px;
    color: $C_GREY_DARK;
    font-size: 16px;
    pointer-events: none;
    transition: top 0.2s ease-out, font-size 0.2s ease-out;

    .is-multi-select & {
        color: $C_GREEN_DARK;
    }
}

.form-select__value {
    @include fluid('bottom', 7px, 10px);

    position: absolute;
    left: 25px;
    color: $C_GREEN_DARK;
    font-size: 16px;
}

.form-select__trigger {
    @include form-input-element;
    position: relative;
    z-index: 1;
    padding-top: 0px;
    border-bottom: 0;
    color: $C_GREY_DARK;
    transition: opacity 0.3s ease-in-out, border 0.2s ease-in-out;
    .is-multi-select & {
        color: $C_GREEN_DARK;
    }

    &:focus {
        border-color: $C_GREEN_DARK;
    }
    .is-open & {
        border-color: $C_PRIMARY;
    }

    &.is-full-screen {

        border-bottom: 2px solid $C_GREEN_DARK;

        &.is-hidden {
            display: none;
        }
    }

    .app-icon {
        position: absolute;
        top: 4px;
        right: 20px;
        bottom: 0;
        height: auto;
        margin: auto;
        pointer-events: none;
        transition: transform 0.3s $EASE_IN_OUT--CIRC;

        .is-open & {
            transform: rotate(-180deg);
        }
    }
}

.form-select__amount {
    position: absolute;
    top: -2px;
    right: 57px;
    bottom: 0;
    display: flex;
    width: 34px;
    height: 34px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: auto;
    background:$C_PRIMARY;
    color: $C_WHITE;
    line-height: 1;
}

.form-select__options {
    @include remove-scrollbars();

    position: absolute;
    z-index: 4;
    overflow: hidden;
    width: 100%;
    max-height: v-bind(optionsHeight);
    padding: 0 24px;
    border: 2px solid $C_GREEN_DARK;
    border-radius:  0 0 6px 6px;
    border-top: 0;
    margin-top: -6px;
    background: $C_WHITE;
    color: $C_GREEN_DARK;
    overflow-y: scroll;
    transition: max-height 0.3s $EASE_IN_OUT--CIRC, border 0.2s ease-in-out;

    .is-empty & {
        display: none;
    }

    .is-open & {
        border-color: $C_PRIMARY;
        opacity: 1;
        pointer-events: all;
    }

    li {
        @include typo-font('regular');
        position: relative;
        padding: 18px 25px;
        padding-right: 50px;
        cursor: pointer;
        font-size: 16px;
        opacity: 0;
        transition: opacity 0.2s ease-in-out, padding 0.2s ease-in-out;

        .is-open & {
            opacity: 1;
        }

        .is-full-screen & {
            &.checked {
                padding-left: 25px;
            }
        }

        &:after {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 2px;
            border-radius: 5px;
            background: $C_GREY_DARK;
            content: '';
            opacity: 0.1;
        }

        &:first-child {
            margin-top: 10px;
            &:before {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 2px;
                border-radius: 5px;
                background: $C_GREY_DARK;
                content: '';
                opacity: 0.1;
            }
        }
    }
}

.form-select__option-background {
    position: absolute;
    z-index: -1;
    top: 7px;
    right: 7px;
    bottom: 7px;
    left: 7px;
    border-radius: 6px;
    background: #E9EBC1;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s ease-in-out;

    .checked & {
        opacity: 1;
    }
}

.form-select__option-checked {
    position: absolute;
    top: 0;
    right: 20px;
    bottom: 0;
    display: flex;
    width: 19px;
    height: 19px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: auto;
    background: transparent;
    transform: scale(0.8);
    transition: background 0.30s $EASE_OUT--CIRC, transform 0.35s $EASE_OUT--BACK;

    svg {
        transform: scale(0);
        transition: transform 0.35s $EASE_OUT--CIRC;
    }

    .checked & {
        background: $C_PRIMARY;
        transform: scale(1);

        svg {
            transform: scale(1);
        }
    }
}

.form-select__option-count {
    @include z-index('bullets');
    position: absolute;
    top: 0;
    right: 15px;
    bottom: 0;
    display: flex;
    width: 30px;
    height: 30px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: auto;
    background: $C_PRIMARY;
    color: $C_WHITE;
    font-size: 10px;
}

.form-select__fullscreen-close {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 27px 25px;
    padding-right: 0;
    padding-bottom: 0;
    margin-bottom: 31px;
}

.form-select__fullscreen-reset {
    @include typo-font('regular');
    color: $C_PRIMARY;
    font-size: 16px;
}

.form-select__fullscreen-label {
    @include typo-font('regular');
    @include typo-size('h3');
    position: relative;

    padding: 27px 25px;
    padding-top: 0;

    &:after {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        border-radius: 5px;
        background: $C_GREY_DARK;
        content: '';
        opacity: 0.1;
    }
}

.form-select__full-screen-footer {
    position: fixed;
    z-index: 100;
    bottom: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100px;
    align-items: center;
    justify-content: center;
    background: $C_GREY_LIGHT;
}

</style>
